.rbc-day-bg{
    cursor: pointer;
}

.rbc-date-cell .dayHeaderContainer{
    display:flex;
}

.rbc-date-cell{
    background-color: #f1f1f1;
    border: 1px solid #DDDDDD;
}

.rbc-date-cell .hourSum{
    flex:1;
    text-align: left;
}

.rbc-date-cell .dayNumber{
}

.rbc-date-cell.rbc-current .dayNumber{
    background: #ffdc4c;
    border-radius: 50%;
    /* padding: 3px; */
}

.rbc-event{
    font-size:0.8em;
}