mark{
    background: inherit;
    font-weight: 900;
}

.selectAllButton{
    color: #cccccc;
    padding: 8px;
}

.selectAllButton:hover {
    color:#999999;
}